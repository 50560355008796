export default {
	duration: {
		timeout: 3000,
		effect: 600,
		enter: 200,
		leave: 200,
		cancel: 300
	},
	easing: {
		linear: 'cubic-bezier(0, 0, 1, 1)',
		standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
		deceleration: 'cubic-bezier(0, 0, 0.2, 1)',
		acceleration: 'cubic-bezier(0.4, 0, 1, 1)'
	}
};
